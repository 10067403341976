export const StringsEN = {
    // Registration and authentication processes
    authentication: {
        register_success: 'Verify your email address using the link that we have sent you',
        error_emailNotVerified: 'The email has not been verified yet',
        resetPasswordemail_sent: 'An email has been sent to ',
        resetPasswordemail_emailNotFound: 'User not found',
        resetPasswordemail_unknownError: 'Unknown error, try again later or contact us if it does not work',
        resetPasswordemail_required: 'New password required',
    },
    // Popups
    popups: {
        input_box_label_user: 'User',
        input_box_label_password: 'Password',
        resetPasswordPopup_title: 'Reset password',
        title_verifyemail: 'Email not verified',
        text_verifyEmail: 'Send verification email to ',
        title_idToken: 'Error',
        text_idToken: 'Unable to get authorization',
    },
    // Buttons
    buttons: {
        button_back: 'Back',
        button_login: 'Login',
        button_accept: 'Accept',
        button_cancel: 'Cancel',
        button_logout: 'Logout',
        button_settings: 'Settings',
        button_save: 'Save',
        button_exit: 'Exit',
        button_edit: 'Edit',
        button_update: 'Update',
        button_disableAccount: 'Disable account',
        button_deleteAccount: 'Delete account',
        buttons_downloadUserList: 'Download user list'
    },
    // Errors
    errors: {
        // Login errros 
        login_error_unknown_user: 'User with no access rights',
        login_error_unknown: 'Unknown login error',
        // Format (generic validators)
        error_maxValue: 'Value too large',
        error_minValue: 'Value to small',
        error_step: 'Resolution error',
        error_required: 'Required field',
        error_maxLength: 'To many characters',
        error_specialCharacters: 'Special characters are not allowed',
        error_spacesInString: 'Spaces are not allowed',
    },
    // Global form / Specific form fields
    booleanFormField: {
        tag_positive: 'Yes',
        tag_negative: 'No'
    },
    // Landing page strings
    landingPageStrings: {
        optionMenu_accountConfig: 'License type',
        optionMenu_accountConfig_valueCustom: 'Custom',
        tab_newUser: 'New user',
        tab_existingUser: 'Existing user', 
        input_email: 'Email',
        input_nDays: 'Duration (days)',
        input_nAnalysis: 'Number of analysis',
        label_startingDate: 'Starting date',
        label_updateDate: 'Last update',
        label_endingDate: 'Ending date',
        label_nDays: 'Duration (days)',
        label_nAnalysis: 'Number of analysis',
        label_nRemainingAnalysis: 'Number of remaining analysis',
        label_disabledAccount: 'Disabled account',
        label_userTrust: 'User trust for training models',
        button_disableAccount: 'Disable account',
        button_deleteAccount: 'Delete account',
        toggle_updateLicenseDaysAndAnalysis: 'Modify parameters',
        toggle_dangerousAcctions: 'Critical actions',
        waitingMessage_NewUser: 'Creating new user',
        waitingMessage_GetUser: 'Searching user information',
        waitingMessage_UpdateUser: 'Updating user',
        waitingMessage_DeleteUser: 'Deleting user account',
        waitingMessage_DownloadUserList: 'Downloading user list',
        informationPopup_userUpdated_title: 'Success',
        informationPopup_userUpdated_message: 'User correctly updated',
        informationPopup_newUserSuccess_title: 'Success',
        informationPopup_newUserSuccess_message: 'User correctly created',
        informationPopup_userDeleted_title: 'Success',
        informationPopup_userDeleted_message: 'User account deleted',
        informationPopup_error_title: 'Error',
        criticalAccion_title: 'Alert',
        criticalAccion_disableAccount: 'Disable account?',
        criticalAccion_deleteAccount: 'Delete account?',
        toggle_unlimitedAnalysis: 'Unlimited analysis',
        toggle_pacsEnable: 'PACS integration available',
        toggle_hisEnable: 'HIS integration available',
    },
    loginPageStrings: {
        waitingMessage_Login: 'Verifying credentials',
    }
   
}