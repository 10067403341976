

<mat-toolbar color="primary">
    <button mat-icon-button (click)="button_downloadUserList()">
        <mat-icon>download</mat-icon>
      </button>
    <span (click)="button_downloadUserList()" style="cursor:pointer;">{{languageService.Strings.buttons.buttons_downloadUserList}}</span>
    

    <span class="toolbar-spacer"></span>
    <span (click)="button_logout()" style="cursor:pointer;">{{controllerService.userAuthenticationService.getUserEmail()}}</span>
    <button mat-icon-button (click)="button_logout()">
      <mat-icon>logout</mat-icon>
    </button>
</mat-toolbar>

<!--style="overflow: hidden;"-->
<mat-tab-group  (selectedTabChange)="onTabChanged($event)">
    <!-- Tab 1 - New user -->
    <mat-tab label="{{languageService.Strings.landingPageStrings.tab_newUser}}" > 

        <!-- Form - New user-->
        <form [formGroup]="formControlGroup_NewUser" class=global-form>


            <!-- Form field - email -->
            <mat-form-field appearance="fill" class="full_width">
                <mat-label>
                    {{languageService.Strings.landingPageStrings.input_email}}
                </mat-label>
                <input class="input-test"
                    matInput
                    id="email"
                    type="email" 
                    placeholder="" 
                    [formControl]="formControl_newUser_email" 
                    [errorStateMatcher]="matcher">
                <mat-error *ngIf="formControl_newUser_email.hasError('required')" >
                            {{languageService.Strings.errors.error_required}}
                </mat-error>
                <mat-error *ngIf="formControl_newUser_email.hasError('spacesInStringValidationError') ||
                                  formControl_newUser_email.hasError('email')" >
                    {{languageService.Strings.errors.error_email}}
                </mat-error>                
            </mat-form-field>

            
            <!-- Checkboxes - License type -->
            <div class="pannel-checkboxes-container">
                <div *ngFor="let categoryDict of license_types ; let i = index"
                    class="single-checkbox-container-div">
                    <mat-checkbox 
                        color="primary" 
                        class="single-checkbox-container" 
                        (change)="handleChangeCheckBox_newUser($event, i)"
                        [disabled]="disabledAccount"
                        [checked]="getCategoryIsChecked_newUser(categoryDict.key)">
                        {{categoryDict.name}}
                    </mat-checkbox>
                </div>
            </div>


            <!-- Button - Toggle - PACS -->
            <div class="pannel-slider-container">
                <mat-slide-toggle
                    class="toggle_button"
                    [checked]="toggleState_newUser_PACS"
                    (change)="onToggleChange_newUser_PACS($event)">
                    <span class="toggle_text">
                        {{languageService.Strings.landingPageStrings.toggle_pacsEnable}}
                    </span>
                </mat-slide-toggle>
            </div>
            <!-- Button - Toggle - HIS -->
            <div class="pannel-slider-container">
                <mat-slide-toggle
                    class="toggle_button"
                    [checked]="toggleState_newUser_HIS"
                    (change)="onToggleChange_newUser_HIS($event)">
                    <span class="toggle_text">
                        {{languageService.Strings.landingPageStrings.toggle_hisEnable}}
                    </span>
                </mat-slide-toggle>
            </div>


            <!-- Button - Mat menu - Account mode -->
            <button 
                mat-raised-button 
                class="base_buttons full_width button_with_icon" style="margin-bottom: 10px;"
                [matMenuTriggerFor]="newUser_menuAccountConfig" 
                >
                {{optionMenu_AccountConfig_newUser_strValue}}
                <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>                
            </button>
            <mat-menu #newUser_menuAccountConfig="matMenu" yPosition="below">
                <button mat-menu-item *ngFor="let item of optionMenu_AccountConfig_OptionList; let i=index" 
                (click)="optionMenuClick_AccountConfig_newUser(i)" >
                    {{item.name}}
                </button>
            </mat-menu>


            <!-- Form field - nDays -->
            <mat-form-field appearance="fill" class="full_width">
                <mat-label>
                    {{languageService.Strings.landingPageStrings.input_nDays}}
                </mat-label>
                <input 
                    type="number" 
                    min={{nDays_minValue}} 
                    max={{nDays_maxValue}} 
                    step={{nDays_stepValue}} 
                    placeholder="" 
                    matInput 
                    [formControl]="formControl_newUser_nDays"
                    [errorStateMatcher]="matcher" 
                    required
                    autocomplete="off">
                <mat-error *ngIf="formControl_newUser_nDays.hasError('required')">
                    {{languageService.Strings.errors.error_required}}
                </mat-error>
                <mat-error *ngIf="formControl_newUser_nDays.hasError('max')">
                    {{languageService.Strings.errors.error_maxValue}}
                </mat-error>
                <mat-error *ngIf="formControl_newUser_nDays.hasError('min')">
                    {{languageService.Strings.errors.error_minValue}}
                </mat-error>
                <mat-error *ngIf="formControl_newUser_nDays.hasError('stepValidationError')">
                    {{languageService.Strings.errors.error_step}}
                </mat-error>              
            </mat-form-field>

    
            <!-- Button - Toggle - unlimitedAnalysis -->
            <div class="pannel-slider-container">
                <mat-slide-toggle
                    class="toggle_button"
                    [disabled]="!toggleEnable_button_newUser_unlimitedAnalysis"
                    [checked]="toggleState_newUser_unlimitedAnalysis"
                    (change)="onToggleChange_newUser_unlimitedAnalysis($event)">
                    <span class="toggle_text">
                        {{languageService.Strings.landingPageStrings.toggle_unlimitedAnalysis}}
                    </span>
                </mat-slide-toggle>
            </div>


            <!-- Form field - nAnalysis -->
            <mat-form-field appearance="fill" class="full_width">
                <mat-label>
                    {{languageService.Strings.landingPageStrings.input_nAnalysis}}
                </mat-label>
                <input 
                    type="number" 
                    min={{nAnalysis_minValue}} 
                    max={{nAnalysis_maxValue}} 
                    step={{nAnalysis_stepValue}} 
                    placeholder="" 
                    matInput 
                    [formControl]="formControl_newUser_nAnalysis"
                    [errorStateMatcher]="matcher" 
                    required
                    autocomplete="off">
                <mat-error *ngIf="formControl_newUser_nAnalysis.hasError('required')">
                    {{languageService.Strings.errors.error_required}}
                </mat-error>
                <mat-error *ngIf="formControl_newUser_nAnalysis.hasError('max')">
                    {{languageService.Strings.errors.error_maxValue}}
                </mat-error>
                <mat-error *ngIf="formControl_newUser_nAnalysis.hasError('min')">
                    {{languageService.Strings.errors.error_minValue}}
                </mat-error>
                <mat-error *ngIf="formControl_newUser_nAnalysis.hasError('stepValidationError')">
                    {{languageService.Strings.errors.error_step}}
                </mat-error>              
            </mat-form-field>


            

            


            <!-- Action buttons (Accept / Cancel) -->  
            <div class="base_buttons_container">      
                <button 
                    mat-raised-button 
                    color="primary"
                    class="base_buttons full_width"
                    type="button" 
                    (click)="button_NewUser_Cancel()">
                        {{languageService.Strings.buttons.button_cancel}}
                </button>          
                <button 
                    mat-raised-button 
                    color="primary"
                    class="base_buttons full_width"
                    type="button" 
                    (click)="button_NewUser_Accept()"
                    [disabled]="!formControlGroup_NewUser.valid || !optionMenu_AccountConfig_newUser_predefinedOptionSelected">
                        {{languageService.Strings.buttons.button_accept}}
                </button>
            </div>
        </form>    

    </mat-tab>






    <!-- Tab 2 - Existing user -->
    <mat-tab label="{{languageService.Strings.landingPageStrings.tab_existingUser}}">
        
        <!-- Form - Get user-->
        <form [formGroup]="formControlGroup_GetUser" class=global-form>

            <!-- Form field - email -->
            <mat-form-field appearance="fill" class="full_width">
                <mat-label>
                    {{languageService.Strings.landingPageStrings.input_email}}
                </mat-label>
                <input class="input-test"
                    matInput
                    id="email"
                    type="email" 
                    placeholder="" 
                    [formControl]="formControl_getUser_email" 
                    [errorStateMatcher]="matcher">
                <mat-error *ngIf="formControl_getUser_email.hasError('required')" >
                            {{languageService.Strings.errors.error_required}}
                </mat-error>
                <mat-error *ngIf="formControl_getUser_email.hasError('spacesInStringValidationError') ||
                                  formControl_getUser_email.hasError('email')" >
                    {{languageService.Strings.errors.error_email}}
                </mat-error>                
            </mat-form-field>


            <!-- Stargint date - ending date -->
            <div class="information-div"  *ngIf="userFound">
                <p>{{languageService.Strings.landingPageStrings.label_startingDate}} - {{creationTimestamp}}</p>
                <p>{{languageService.Strings.landingPageStrings.label_updateDate}} - {{updateTimestamp}}</p>
                <p>{{languageService.Strings.landingPageStrings.label_endingDate}} - {{endingTimestamp}}</p>
                <p>{{languageService.Strings.landingPageStrings.label_nDays}} - {{nDays}}</p>
                <p>{{languageService.Strings.landingPageStrings.label_nAnalysis}} - {{nAnalysis}}</p>
                <p>{{languageService.Strings.landingPageStrings.label_nRemainingAnalysis}} - {{nRemainingAnalysis}}</p>    
                <p>{{languageService.Strings.landingPageStrings.label_disabledAccount}} - {{disabledAccount}}</p>
            </div>    

        </form>    


        <!-- Form - Update user-->
        <form [formGroup]="formControlGroup_UpdateUser" class=global-form *ngIf="userFound">

            <!-- Checkboxes - License type -->
            <div class="pannel-checkboxes-container">
                <div *ngFor="let categoryDict of license_types ; let i = index"
                    class="single-checkbox-container-div">
                    <mat-checkbox 
                        color="primary" 
                        class="single-checkbox-container" 
                        (change)="handleChangeCheckBox_updateUser($event, i)"
                        [disabled]="!userBeingEdited"
                        [checked]="getCategoryIsChecked_updateUser(categoryDict.key)">
                        {{categoryDict.name}}
                    </mat-checkbox>
                </div>
            </div>



            <!-- Panel - Toggle - PACS -->
            <div class="pannel-slider-container">
                <mat-slide-toggle
                    class="toggle_button"
                    [disabled]="!userBeingEdited"
                    [checked]="toggleState_updateUser_PACS"
                    (change)="onToggleChange_updateUser_PACS($event)">
                    <span class="toggle_text">
                        {{languageService.Strings.landingPageStrings.toggle_pacsEnable}}
                    </span>
                </mat-slide-toggle>
            </div>
            <!-- Panel - Toggle - HIS -->
            <div class="pannel-slider-container">
                <mat-slide-toggle
                    class="toggle_button"
                    [disabled]="!userBeingEdited"
                    [checked]="toggleState_updateUser_HIS"
                    (change)="onToggleChange_updateUser_HIS($event)">
                    <span class="toggle_text">
                        {{languageService.Strings.landingPageStrings.toggle_hisEnable}}
                    </span>
                </mat-slide-toggle>
            </div>
            


            <!-- Panel - License nDays and nAnalysis -->
            <div class="pannel-slider-container">

                <!-- Button - Toggle - Update or not the license days and analysis -->
                <mat-slide-toggle
                    class="toggle_button"
                    [disabled]="!userBeingEdited"
                    [checked]="toggleState_updateUser"
                    (change)="onToggleChange_updateUser($event)">
                    <span class="toggle_text">
                        {{languageService.Strings.landingPageStrings.toggle_updateLicenseDaysAndAnalysis}}
                    </span>
                </mat-slide-toggle>

                <!-- Button - Mat menu - Account mode -->
                <button 
                    mat-raised-button 
                    class="base_buttons full_width button_with_icon" style="margin-bottom: 10px;"
                    [matMenuTriggerFor]="updateUser_menuAccountConfig" 
                    [disabled]="!userBeingEdited || !toggleState_updateUser"
                    >
                    {{optionMenu_AccountConfig_updateUser_strValue}}
                    <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>                
                </button>
                <mat-menu #updateUser_menuAccountConfig="matMenu" yPosition="below">
                    <button mat-menu-item *ngFor="let item of optionMenu_AccountConfig_OptionList; let i=index" 
                    (click)="optionMenuClick_AccountConfig_updateUser(i)" >
                        {{item.name}}
                    </button>
                </mat-menu>

                <!-- Form field - nDays -->
                <mat-form-field appearance="fill" class="full_width" >
                    <mat-label>
                        {{languageService.Strings.landingPageStrings.input_nDays}}
                    </mat-label>
                    <input 
                        type="number" 
                        min={{nDays_minValue}} 
                        max={{nDays_maxValue}} 
                        step={{nDays_stepValue}} 
                        placeholder="" 
                        matInput 
                        [formControl]="formControl_updateUser_nDays"
                        [errorStateMatcher]="matcher" 
                        required
                        autocomplete="off">
                    <mat-error *ngIf="formControl_updateUser_nDays.hasError('required')">
                        {{languageService.Strings.errors.error_required}}
                    </mat-error>
                    <mat-error *ngIf="formControl_updateUser_nDays.hasError('max')">
                        {{languageService.Strings.errors.error_maxValue}}
                    </mat-error>
                    <mat-error *ngIf="formControl_updateUser_nDays.hasError('min')">
                        {{languageService.Strings.errors.error_minValue}}
                    </mat-error>
                    <mat-error *ngIf="formControl_updateUser_nDays.hasError('stepValidationError')">
                        {{languageService.Strings.errors.error_step}}
                    </mat-error>              
                </mat-form-field>

                <!-- Button - Toggle - unlimitedAnalysis -->
                <div class="pannel-slider-container">
                    <mat-slide-toggle
                        class="toggle_button"
                        [disabled]="!(toggleEnable_button_updateUser_unlimitedAnalysis && userBeingEdited)"
                        [checked]="toggleState_updateUser_unlimitedAnalysis"
                        (change)="onToggleChange_updateUser_unlimitedAnalysis($event)">
                        <span class="toggle_text">
                            {{languageService.Strings.landingPageStrings.toggle_unlimitedAnalysis}}
                        </span>
                    </mat-slide-toggle>
                </div>

                <!-- Form field - nAnalysis -->
                <mat-form-field appearance="fill" class="full_width">
                    <mat-label>
                        {{languageService.Strings.landingPageStrings.input_nAnalysis}}
                    </mat-label>
                    <input 
                        type="number" 
                        min={{nAnalysis_minValue}} 
                        max={{nAnalysis_maxValue}} 
                        step={{nAnalysis_stepValue}} 
                        placeholder="" 
                        matInput 
                        [formControl]="formControl_updateUser_nAnalysis"
                        [errorStateMatcher]="matcher" 
                        required
                        autocomplete="off">
                    <mat-error *ngIf="formControl_updateUser_nAnalysis.hasError('required')">
                        {{languageService.Strings.errors.error_required}}
                    </mat-error>
                    <mat-error *ngIf="formControl_updateUser_nAnalysis.hasError('max')">
                        {{languageService.Strings.errors.error_maxValue}}
                    </mat-error>
                    <mat-error *ngIf="formControl_updateUser_nAnalysis.hasError('min')">
                        {{languageService.Strings.errors.error_minValue}}
                    </mat-error>
                    <mat-error *ngIf="formControl_updateUser_nAnalysis.hasError('stepValidationError')">
                        {{languageService.Strings.errors.error_step}}
                    </mat-error>              
                </mat-form-field>   
            </div>


            <!-- Panel - Critical actions -->
            <div class="pannel-account-button-container">
                <!-- Button - Toggle - Disable | Delete user account -->
                <mat-slide-toggle
                    class="toggle_button"
                    [disabled]="!userBeingEdited"
                    [checked]="toggleState_criticalActions"
                    (change)="onToggleChange_criticalActions($event)">
                    <span class="toggle_text">
                        {{languageService.Strings.landingPageStrings.toggle_dangerousAcctions}}
                    </span>
                </mat-slide-toggle>
                <!-- Action buttons (Disable / Delete account) -->  
                <div class="base_buttons_container">      
                    <!-- Button - Disable account -->
                    <button 
                        *ngIf="userFound"
                        mat-raised-button 
                        color="primary"
                        class="base_buttons full_width"
                        type="button" 
                        (click)="button_CriticalAction_DisableAccount()"
                        [disabled]="!toggleState_criticalActions">
                            {{languageService.Strings.landingPageStrings.button_disableAccount}}
                    </button>  
                    <!-- Button - Delete account -->
                    <button 
                        *ngIf="userFound"
                        mat-raised-button 
                        color="primary"
                        class="base_buttons full_width"
                        type="button" 
                        (click)="button_CriticalAction_DeleteAccount()"
                        [disabled]="!toggleState_criticalActions">
                            {{languageService.Strings.landingPageStrings.button_deleteAccount}}
                    </button>  
                </div>
            </div>
        </form> 





        <!-- Base tab buttons -->
        <div class=base_buttons_container-outer>
            <!-- Action buttons (Accept / Cancel) -->  
            <div class="base_buttons_container">      
                <button 
                    mat-raised-button 
                    color="primary"
                    class="base_buttons full_width"
                    type="button" 
                    (click)="button_GetUser_Cancel()">
                        {{languageService.Strings.buttons.button_cancel}}
                </button>        
                <button 
                    *ngIf="!userFound"
                    mat-raised-button 
                    color="primary"
                    class="base_buttons full_width"
                    type="button" 
                    (click)="button_GetUser_Accept()"
                    [disabled]="!formControlGroup_GetUser.valid">
                        {{languageService.Strings.buttons.button_accept}}
                </button>

                <button 
                    *ngIf="userFound"
                    mat-raised-button 
                    color="primary"
                    class="base_buttons full_width"
                    type="button" 
                    (click)="button_GetUser_Edit()"
                    [disabled]="userBeingEdited">
                        {{languageService.Strings.buttons.button_edit}}
                </button>  
                <button 
                    *ngIf="userFound"
                    mat-raised-button 
                    color="primary"
                    class="base_buttons full_width"
                    type="button" 
                    (click)="button_GetUser_Update()"
                    [disabled]="!formControlGroup_UpdateUser.valid">
                        {{languageService.Strings.buttons.button_update}}
                </button>  
            </div>
        </div>

     </mat-tab>
</mat-tab-group>


<!-- Waiting spinner -->

<div *ngIf="(displayWaitingSpiner)" >
    <app-waiting-spinner [waitingMessage]="waitingMessage" ></app-waiting-spinner> 
</div>