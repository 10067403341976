{
    "values":
    [
        {
            "name": "Custom",
            "nDays": 0,
            "nAnalysis": 0,
            "price": 0
        },
        {
            "name": "15 days test",
            "nDays": 15,
            "nAnalysis": 20,
            "price": 0
        },
        {
            "name": "x100",
            "nDays": 365,
            "nAnalysis": 200,
            "price": 2000
        },
        {
            "name": "x250",
            "nDays": 365,
            "nAnalysis": 500,
            "price": 4500
        },
        {
            "name": "Unlimited",
            "nDays": 365,
            "nAnalysis": 1000000,
            "price": 6500
        }
    ],
    "types":
    [
        {
            "name": "Body composition",
            "key": "bc_abd"
        },
        {
            "name": "Body composition - Neck",
            "key": "bc_neck"
        },
        {
            "name": "BC Training",
            "key": "bc_training"
        }
    ]
}